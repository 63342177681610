import React, { useState, useEffect } from 'react';
import './App.css';
import TypeWriter from 'typewriter-effect';
import { Squircle } from '@squircle-js/react';
import Intro from './js/Intro';
import { FaGithub } from 'react-icons/fa6';

function App() {
  const [position, setPosition] = useState(0);
  const [direction, setDirection] = useState(1);
  const [isAnimating, setIsAnimating] = useState(true);
  const [isJumping, setIsJumping] = useState(false);
  const [isExploding, setIsExploding] = useState(false);
  const [showIntro, setShowIntro] = useState(false); // State to track when to show Intro

  useEffect(() => {
    if (!isAnimating) return;

    const interval = setInterval(() => {
      setPosition(prev => {
        if (prev >= 20) {
          setDirection(-1);
        } else if (prev <= 0) {
          setDirection(1);
        }
        return prev + direction;
      });
    }, 50);

    return () => clearInterval(interval);
  }, [direction, isAnimating]);

  useEffect(() => {
    if (!isAnimating && position === 0) {
      setIsJumping(true);
    }
  }, [isAnimating, position]);

  useEffect(() => {
    if (isExploding) {
      // Set a timeout to switch to Intro.js at 60% of the explosion animation duration
      const timeout = setTimeout(() => {
        setShowIntro(true);
      }, 600); // 60% of 1 second (1000ms) is 600ms

      return () => clearTimeout(timeout);
    }
  }, [isExploding]);

  const handleClick = () => {
    setIsAnimating(false);
    setPosition(0); // Ensure it returns to the initial position
    setIsJumping(true);
  };

  const handleAnimationEnd = () => {
    if (isJumping) {
      setIsJumping(false);
      setIsExploding(true);
    }
  };

  if (showIntro) {
    return ( <Intro /> );
  }

  return (
    <>
      <div className="Home">
        <div className="content">
          <div className="text">
            <h1> 
              Hi, I'm <mark> bagaco </mark>
              <TypeWriter options={{strings: ['Freelancer / Software Developer'], 
                autoStart: true, loop: true, delay: 200}} />

              <p onClick={handleClick}> Click here to explore my portfolio! </p>
            </h1>
          </div>
          <div 
            className={`logo ${isJumping ? 'jump' : ''} ${isExploding ? 'explode' : ''}`} 
            style={{ transform: isAnimating ? `rotate(345deg) translateY(${position}px)` : 'rotate(0deg) translateY(0)' }}
            onAnimationEnd={handleAnimationEnd}
          >
            <Squircle cornerRadius={50} cornerSmoothing={1} 
            width={300} height={300} className='logo-squircle' >
              <img src="/assets/pfp.png" alt="logo" />
            </Squircle>
          </div>
        </div>
      </div>

      <footer> 
        <p> Copyright © 2024 bagaco. All rights reserved. </p>
        <a href="https://github.com/bagacopt" target="_blank" rel='noreferrer noopener'>
          <FaGithub className='icon' size={32} color='lightgray' />
        </a>
      </footer>
    </>
  );
}

export default App;