import '../css/Intro.css';
import Navbar from './Navbar';
import { lazy, Suspense, useState, useEffect } from 'react';
import ErrorPage from '../404';
import { FaGithub, FaInstagram, FaXTwitter, FaTwitch, FaSnapchat, FaSpotify, FaLinkedin } from "react-icons/fa6";

// Lazy load components for code splitting
const About = lazy(() => import('./About'));
const Skills = lazy(() => import('./Skills'));
const Projects = lazy(() => import('./Projects'));
const Contact = lazy(() => import('./Contact'));

// Enum-like object for page identifiers
const Pages = {
  ABOUT: 1,
  SKILLS: 2,
  PROJECTS: 3,
  CONTACT: 4,
};

function Intro() {
  const [activePage, setActivePage] = useState(Pages.ABOUT); // Default to ABOUT

  useEffect(() => {
    const lastSelectedPage = localStorage.getItem('lastSelectedPage');
    if (lastSelectedPage !== null) {
      setActivePage(parseInt(lastSelectedPage, 10));
    }
  }, []);

  // Map of page identifiers to components
  const pageComponents = {
    [Pages.ABOUT]: <About />,
    [Pages.SKILLS]: <Skills />,
    [Pages.PROJECTS]: <Projects />,
    [Pages.CONTACT]: <Contact />,
  };

  return (
    <>
      <div className="Intro">
        <Navbar setActivePage={setActivePage} />

        <div className="info">
          <div className="socials">
            <div className="logos">
              <a href="https://github.com/bagacopt" target="_blank" rel='noreferrer noopener'>
                <FaGithub className='icon' size={32} color='lightgray'/>
              </a>
              <a href="https://www.instagram.com/bagaco.02/" target="_blank" rel='noreferrer noopener'>
                <FaInstagram className='icon' size={32} color='lightgray'/>
              </a>
              <a href="https://x.com/bagaco_02" target="_blank" rel='noreferrer noopener'>
                <FaXTwitter className='icon' size={32} color='lightgray'/>
              </a>
              <a href="https://www.twitch.tv/bagacopt_" target="_blank" rel='noreferrer noopener'>
                <FaTwitch className='icon' size={32} color='lightgray'/>
              </a>
              <a href="https://snapchat.com/t/0RuTEN6Y" target="_blank" rel='noreferrer noopener'>
                <FaSnapchat  className='icon' size={32} color='lightgray'/>
              </a>
              <a href="https://open.spotify.com/user/tomas1234gouveia" target="_blank" rel='noreferrer noopener'>
                <FaSpotify className='icon' size={32} color='lightgray'/>
              </a>
              <a href="https://www.linkedin.com/in/tomas-gouveia2005/" target="_blank" rel='noreferrer noopener'>
                <FaLinkedin className='icon' size={32} color='lightgray'/>
              </a>
            </div>
          </div>

          <Suspense fallback={<div>Loading...</div>}>
            {pageComponents[activePage] || <ErrorPage />}
          </Suspense>
        </div>

        
      </div>

      <footer> 
        <p> Copyright © 2024 bagaco. All rights reserved. </p>
        <a href="https://github.com/bagacopt" target="_blank" rel='noreferrer noopener'>
          <FaGithub className='icon' size={32} color='lightgray' />
        </a>
      </footer>
    </>
  );
}

export default Intro;