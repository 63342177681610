import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import '../css/Navbar.css';

function Navbar({ setActivePage }) {
    const options = [
        { text: 'Home', index: 0 },
        { text: 'About Me', index: 1 },
        { text: 'Skills', index: 2 },
        { text: 'Projects', index: 3 },
        { text: 'Contact', index: 4 }
    ];

    // Use localState to store the selected index
    const [selectedIndex, setSelectedIndex] = useState(() => {
        // Retrieve initial state from localStorage if available
        const savedIndex = localStorage.getItem('lastSelectedPage');
        return savedIndex ? parseInt(savedIndex, 10) : 0; // Default to Home (index 0)
    });

    // Effect hook to update localStorage when selectedIndex changes
    useEffect(() => {
        localStorage.setItem('lastSelectedPage', selectedIndex);
    }, [selectedIndex]);

    const handleOptionClick = (index) => {
        setSelectedIndex(index);
        setActivePage(index);
    };

    return (
        <nav className='nav'>
            <img src="/assets/pfp.png" width={50} height={50} alt="Profile" onClick={() => handleOptionClick(0)} />
            <div className='divisor' />

            <div className='options'>
                {options.map((option) => (
                    <div className='option' key={option.index}>
                        <input
                            type="radio"
                            id={option.text}
                            onChange={() => handleOptionClick(option.index)}
                            checked={selectedIndex === option.index}
                        />
                        <label htmlFor={option.text}> {option.text} </label>
                    </div>
                ))}
            </div>
        </nav>
    );
}

Navbar.propTypes = {
    setActivePage: PropTypes.func.isRequired,
};

export default Navbar;
